import http from "../http-common";

export default class GamesDataServices {
  async getByTitle(title) {
    return http.get(`v1/user/GetGames?Title=${title}`);
  }

  async getAll() {
    return http.get(`v1/user/GetGames`);
  }

  async favorite(id) {
    return http.put(`v1/user/GamesFavorite?GameId=${id}`);
  }
  async UnFavorite(id) {
    return http.put(`v1/user/GamesDisfavor?GameId=${id}`);
  }
}
