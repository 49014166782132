<template>
  <div class="container-card">
    <div class="card">
      <img
        :src="
          game.image_path == null
            ? 'https://cdn.quasar.dev/img/mountains.jpg'
            : url_aws_bucket + game.image_path
        "
        class="img-game"
      />
      <div class="content">
        <div class="wrap">
          <h3>{{ game.title }}</h3>
          <q-icon
            :name="game.favorite_game ? 'favorite' : 'favorite_border'"
            size="sm"
            color="default-pink"
            class="cursor-pointer"
            @click="
              () => {
                favoriteUpdate(game.game_id);
                $emit('favorite');
              }
            "
          />
        </div>
        <p>
          <strong>{{ game.linked_content_type }}:</strong>
          {{ game.linked_content }}
        </p>
        <div>
          <p>
            Postado por
            <strong>{{ game.posted_by }}</strong>
          </p>
          <p>
            Postado em <strong>{{ createdAt }}</strong>
          </p>
        </div>
        <q-btn
          color="default-pink"
          text-color="white"
          label="Acessar"
          unelevated
          target="_blank"
          rel="noopener noreferrer"
          :to="{
            name: 'game-intro',
            query: {
              gameId: game.game_id,
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* Vue */
import { computed, ref } from "vue";
/* Services */
import GamesDataServices from "@/services/GamesDataServices";

/* UTILS */
import { ENV  } from "@/utils/env";

export default {
  name: "HorizontalCard",
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  emits: ["favorite"],
  setup({ game }) {
    /* Services */
    const _GamesDataServices = new GamesDataServices();

    const url_aws_bucket =ENV.URL_AWS_BUCKET;

    /* lifecycles */
    const createdAt = computed(
      () => `${new Date(game.created_at).toLocaleDateString()}`
    );

    /* functions */

    async function favoriteUpdate(id) {
      if (game.favorite_game) {
        await _GamesDataServices
          .UnFavorite(id)
          .then((response) => {})
          .catch((error) => console.log(error));
      } else {
        await _GamesDataServices
          .favorite(id)
          .then((response) => {})
          .catch((error) => console.log(error));
      }
    }

    return {
      /* lifecycles */
      createdAt,
      /* Functions */
      favoriteUpdate,
      url_aws_bucket,
    };
  },
};
</script>

<style scoped>
.container-card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 1.07293px 4.29171px rgba(0, 0, 0, 0.15);
  border-radius: 4.29171px;
}

.card {
  display: flex;
  padding-right: 20px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

img {
  flex: 2;
  margin-right: 30px;
  border-top-left-radius: 4.29171px;
  border-bottom-left-radius: 4.29171px;
  width: 300px;
  height: 250px;
}

.q-btn {
  margin-top: 12px;
}

.q-icon {
  align-self: baseline;
}

h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #0e0708;
  margin-bottom: 0.5rem;
  margin-right: 10px;
  width: 109.44px;
}

p {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #a6a6a6;
}

strong {
  color: var(--q-primary, #fe4e64);
}

@media (min-width: 0) and (max-width: 560px) {
  .card {
    flex-direction: column;
    padding-right: 0px;
    padding-bottom: 10px;
  }

  img {
    flex: 0;
    margin-right: 0px;
    border-top-right-radius: 4.29171px;
    border-bottom-left-radius: 0;

    width: 100%;
    height: 250px;
  }

  .wrap {
    margin-top: 10px;
  }
  .content {
    margin: 0 10px;
  }
}
</style>
