<template>
  <div>
    <q-card class="my-card q-mx-sm">
      <q-img
        :src="
          game.image_path == null
            ? 'https://cdn.quasar.dev/img/mountains.jpg'
            : url_aws_bucket + game.image_path
        "
        class="img-game"
      />
      <q-card-section>
        <div class="header-card">
          <div>
            <h3>{{ game.title }}</h3>
            <p>
              <strong>{{ game.linked_content_type }}:</strong>
              {{ game.linked_content }}
            </p>
          </div>
          <q-icon
            :name="game.favorite_game ? 'favorite' : 'favorite_border'"
            size="sm"
            color="default-pink"
            class="cursor-pointer"
            @click="
              () => {
                favoriteUpdate(game.game_id);
                $emit('favorite');
              }
            "
          />
        </div>
        <div>
          <p>
            Postado por
            <strong>{{ game.posted_by }}</strong>
          </p>
          <p>
            Postado em <strong>{{ createdAt }}</strong>
          </p>
        </div>
      </q-card-section>
      <q-card-actions class="q-pt-none">
        <q-btn
          color="default-pink"
          text-color="white"
          label="Acessar"
          unelevated
          target="_blank"
          rel="noopener noreferrer"
          :to="{
            name: 'game-intro',
            query: {
              gameId: game.game_id,
            },
          }"
        />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
/* Vue */
import { computed, ref, watch } from "vue";
/* Services */
import GamesDataServices from "@/services/GamesDataServices";

/* UTILS */
import { ENV } from "@/utils/env";

export default {
  name: "CardGame",
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  emits: ["favorite"],
  setup({ game }) {
    /* Services */
    const _GamesDataServices = new GamesDataServices();
    const url_aws_bucket = ENV.URL_AWS_BUCKET;

    /* lifecycles */
    const createdAt = computed(
      () => `${new Date(game.created_at).toLocaleDateString()}`
    );

    /* functions */

    async function favoriteUpdate(id) {
      if (game.favorite_game) {
        await _GamesDataServices
          .UnFavorite(id)
          .then((response) => {})
          .catch((error) => console.log(error));
      } else {
        await _GamesDataServices
          .favorite(id)
          .then((response) => {})
          .catch((error) => console.log(error));
      }
    }

    return {
      /* lifecycles */
      createdAt,
      /* Functions */
      favoriteUpdate,
      url_aws_bucket,
    };
  },
};
</script>

<style scoped>
.my-card {
  width: 300px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 800px) {
  .my-card {
    width: 320px;
  }
}

.q-btn {
  width: 100%;
}

.header-card {
  display: flex;
  justify-content: space-between;
}

h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #0e0708;
  margin-bottom: 0.5rem;
}

p {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #a6a6a6;
}

strong {
  color: var(--q-primary, #fe4e64);
}
</style>
