<template>
  <div class="container">
    <Mural class="q-mt-md" :breadcrumbs="breadcrumbs" route="home"></Mural>
    <header class="q-mt-xl">
      <q-input
        color="default-pink"
        v-model="searchGame"
        @keyup="getGameByTitle"
        outlined
        autofocus
        rounded
        type="search"
        placeholder="Busque por uma palavra-chave"
      >
        <template v-slot:prepend>
          <q-icon name="search" />
        </template>
        <template v-slot:append>
          <q-icon
            v-if="searchGame !== ''"
            name="close"
            @click="searchGame = ''"
            class="cursor-pointer"
          />
        </template>
      </q-input>
      <div class="q-my-sm">
        <p class="games-found" v-show="searchGame">
          {{ gameCount }}
        </p>
        <h1>Conteúdos interativos</h1>
      </div>
    </header>
    <section class="favorite-games">
      <h2 class="title">Favoritos</h2>
      <div v-if="favorites.length > 0">
        <div class="carousel q-py-sm">
          <div
            v-for="(game, index) in favorites"
            :key="index"
            class="carousel-item"
          >
            <CardGame
              :game="game"
              @favorite="
                (value) => {
                  callFunction();
                }
              "
            />
          </div>
        </div>
      </div>
      <div v-else-if="favorites.length == 0 && searchGame == ''">
        <p class="games-found">
          Você tem 0 conteúdos interativos adiconados ao seu favoritos!
        </p>
      </div>
      <div v-else-if="favorites.length == 0 && searchGame !== ''">
        <p class="games-found">
          Você não tem nenhum conteúdo interativo adiconado ao seu favoritos
          baseado nessa busca
        </p>
      </div>
    </section>
    <section class="popular-games">
      <h2 class="title">Conteúdos interativos populares</h2>
      <div class="carousel q-py-sm">
        <div
          v-for="(game, index) in popularGames"
          :key="index"
          class="carousel-item"
        >
          <CardGame
            :game="game"
            @favorite="
              (value) => {
                callFunction();
              }
            "
          />
        </div>
      </div>
    </section>
    <section class="all-games">
      <h2 class="title">Todos os conteúdos</h2>
      <div class="pagination-container">
        <div
          v-for="(game, index) in pagination"
          :key="index"
          class="pagination-item"
        >
          <HorizontalCard
            :game="game"
            @favorite="
              (value) => {
                callFunction();
              }
            "
          />
        </div>
      </div>
      <q-pagination
        color="default-pink"
        direction-links
        boundary-links
        v-model="current"
        :min="currentPage"
        :max="Math.ceil(allGames.length / totalItemsPerPage)"
        class="q-mt-md self-end"
      />
    </section>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
/* Components */
import Mural from "@/components/Mural.vue";
import CardGame from "@/components/games/cards/CardGame.vue";

/* Vue */
import { ref, onMounted, watch, computed } from "vue";

import GamesDataServices from "@/services/GamesDataServices";
import HorizontalCard from "@/components/games/cards/HorizontalCard.vue";

export default {
  name: "Launcher",
  components: {
    Mural,
    CardGame,
    HorizontalCard,
  },
  setup(props) {
    /* Constants */
    const breadcrumbs = ref(["Interatividade"]);
    const searchGame = ref("");
    const favorites = ref([]);
    const popularGames = ref([]);
    const allGames = ref([]);
    const update = ref(false);
    const currentPage = ref(1);
    const current = ref(1);
    const totalItemsPerPage = ref(6);
    const loading = ref(true);

    /* Services */
    const _GamesDataServices = new GamesDataServices();

    /* Lifecycles */
    onMounted(() => {
      getAllGames();
    });

    watch(searchGame, (newSearchGames) => {
      if (newSearchGames.trim().length == 0) {
        getAllGames();
      }
    });

    function callFunction() {
      setTimeout(() => {
        getAllGames();
      }, 3000);
    }

    const gameCount = computed(() => {
      if (popularGames.value.length == 1) {
        return `Foi encontrado ${
          popularGames.value.length
        } um conteúdo interativo que contém ${
          searchGame.value.length > 3 ? "o nome" : "a(s) letra(s)"
        } "${searchGame.value}"`;
      } else if (popularGames.value.length == 0) {
        return `Não foi encontrado nenhum conteúdo interativo que contenha  ${
          searchGame.value.length > 3 ? " esse nome" : " a(s) letra(s)"
        } "${
          searchGame.value
        }", por favor verifique se você digitou o nome do conteúdo interativo corretamente!`;
      } else {
        return `Foram encontrados ${
          popularGames.value.length
        } conteúdos interativos que contém ${
          searchGame.value.length > 3 ? "o nome" : "a(s) letra(s)"
        }  "${searchGame.value}"`;
      }
    });

    const pagination = computed(() => {
      return allGames.value.slice(
        (current.value - 1) * totalItemsPerPage.value,
        (current.value - 1) * totalItemsPerPage.value + totalItemsPerPage.value
      );
    });

    /* FUNCTIONS */

    function favoritesFilter(games) {
      favorites.value = games.filter((game) => game.favorite_game == true);
    }

    function orderedByMostPopularGames(games) {
      popularGames.value = games.sort(
        (firtsElement, secondElement) => firtsElement - secondElement
      );
    }

    async function getGameByTitle() {
      await _GamesDataServices
        .getByTitle(searchGame.value)
        .then((response) => {
          if (searchGame.value.trim().length != 0) {
            favoritesFilter(response.data);
            orderedByMostPopularGames(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getAllGames() {
      await _GamesDataServices
        .getAll()
        .then((response) => {
          allGames.value = response.data;
          if (searchGame.value.trim().length == 0) {
            favoritesFilter(response.data);
            orderedByMostPopularGames(response.data);
          }
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return {
      /* Constants */
      breadcrumbs,
      searchGame,
      favorites,
      popularGames,
      allGames,
      update,
      totalItemsPerPage,
      currentPage,
      current,
      loading,
      /* Lifecycles */
      gameCount,
      pagination,

      /* Functions */
      getGameByTitle,
      getAllGames,
      callFunction,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
  height: 95%;
  overflow-x: hidden;
}

.favorite-games,
.popular-games {
  cursor: w-resize;
}

.favorite-games,
.popular-games,
.all-games {
  margin-top: 3.125rem;
}

.all-games {
  display: flex;
  flex-direction: column;
  margin: 3.125rem auto 0 auto;
}

.pagination-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.pagination-item {
  margin-top: 20px;
  flex: 0 0 calc(50% - 20px);
}

@media (min-width: 0) and (max-width: 560px) {
  .pagination-item {
    margin-top: 20px;
    flex: 0 0 calc(100% - 20px);
  }
}

.carousel {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.carousel::-webkit-scrollbar-thumb {
  background-color: var(--q-primary);
}

.container-navigation {
  display: flex;
  justify-content: space-around;
}

.games-found {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #808080;
}

h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #000000;
}

.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #2f2f2f;
  margin-bottom: 0.875rem;
}

.title::before {
  content: "";
  margin-right: 8px;
  display: inline-block;
  width: 3px;
  height: 22px;
  background: var(--q-primary);
  border-radius: 0px 2px 2px 0px;
}
</style>
